
//export const API_BASE_URL = 'https://grhin.cl/back/public/index.php/Api/V1';
//export const API_DOWNLOAD_URL = 'https://grhin.cl/back/public';

//export const API_BASE_URL = 'http://vps171-83.ihosting.cl/back/public/Api/V1';
//export const API_DOWNLOAD_URL = 'http://vps171-83.ihosting.cl/back/public';

export const API_BASE_URL = 'https://admgescol.eztaller.cl/back/public/Api/V1';
export const API_DOWNLOAD_URL = 'https://admgescol.eztaller.cl/back/public';

//export const API_DOWNLOAD_URL = 'http://localhost/grhin/back/public';
//export const API_BASE_URL = 'http://localhost/grhin/back/public/Api/V1';
 
//CREATE USER 'vigomaq_user'@'localhost' IDENTIFIED BY 'OGh1Ahw4cie6kaix5Gungi9gogieph';

/*
sudo chown www-data:www-data -R /var/www/
sudo chgrp -R www-data /var/www/
sudo chmod -R g+w /var/www/

SHOW GRANTS FOR user_grhin@'localhost';

GRANT ALL PRIVILEGES ON * . * TO 'user_grhin'@'localhost';
FLUSH PRIVILEGES;
*/