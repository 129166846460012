// ConsultarGestionCard.jsx
import React from 'react';
import { Button, Card, CardContent, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DescriptionIcon from '@mui/icons-material/Description';
import GroupIcon from '@mui/icons-material/Group';

const ConsultarGestionCard = ({ empresaId }) => {
  const navigate = useNavigate();

  const handleConsultarDocumentos = () => {
    navigate('/Documentos');
  };

  const handleConsultarTrabajadores = () => {
    navigate('/Trabajadores');
  };

  return (

    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 , mb: 2 }}>
      <Card sx={{ backgroundColor: '#fafafa', flexGrow: 1, mx: 1 }}>
        <CardContent>
          <Typography variant="h6" sx={{ color: 'black' }}>Documentos de la Empresa</Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>              
            Aquí puedes revisar todos los documentos subidos al sistema
          </Typography>
          <br/>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<DescriptionIcon />}
            onClick={handleConsultarDocumentos}
          >
            Consultar Documentos
          </Button>
        </CardContent>
      </Card>
      <Card sx={{ backgroundColor: '#fafafa', flexGrow: 1, mx: 1 }}>
        <CardContent>
          <Typography variant="h6" sx={{ color: 'black' }}>Listado de Trabajadores</Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>              
            Aquí puedes revisar y actualizar los datos de tus trabajadores       
          </Typography>
          <br/>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<GroupIcon />}
            onClick={handleConsultarTrabajadores}
          >
            Gestionar Trabajadores
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ConsultarGestionCard;

