import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Card, CardContent, Typography, Button } from '@mui/material'; // Import Material-UI components
import ComunicacionesModal from './ComunicacionModal';
import useAuthAxios from '../../axiosSetup'; // Import the configured axios instance
import '../../css/Empresas.css';

const Comunicaciones = () => {
  const [showForm, setShowForm] = useState(false);
  const [showForm1, setShowForm1] = useState(false);
  const [selectedComunicacion, setSelectedComunicacion] = useState(null);
  const [comunicaciones, setComunicaciones] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(4); // Set to 4 for 4 items per page
  const [currentPage, setCurrentPage] = useState(1);

  const userID = useSelector((state) => state.userDNI); // Assuming userID is stored in Redux
  const empresaId = useSelector((state) => state.empresaId); // Assuming empresaId is stored in Redux
  const token = useSelector((state) => state.token);
  const api = useAuthAxios(); // Use the configured axios instance

  // Fetch Comunicaciones on component mount
  useEffect(() => {
    const fetchComunicaciones = async () => {
      try {
        const response = await api.get(`/comunicaciones/all/${token}`);
        const filteredComunicaciones = response.data
          .filter((empr) => empr.empresa_id === empresaId)
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)); // Sort from recent to old

        setComunicaciones(filteredComunicaciones);
      } catch (error) {
        console.error('Error fetching comunicaciones:', error);
      }
    };

    if (token && empresaId) {
      fetchComunicaciones();
    }
  }, [token, empresaId, api]);

  const handleView = (comunicacion) => {
    setSelectedComunicacion(comunicacion);
    setShowForm1(true);
  };

  const handleEdit = (comunicacion) => {
    setSelectedComunicacion(comunicacion);
    setShowForm(true);
  };

  const handleCancel = () => {
    setShowForm(false);
    setSelectedComunicacion(null);
  };

  const handleCardClick = (comunicacion) => {
    setSelectedComunicacion(comunicacion);
    setShowForm(true);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const paginatedComunicaciones = comunicaciones.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalPages = Math.ceil(comunicaciones.length / itemsPerPage);

  return (
    <div className="container Comunicaciones">
      <h3>Comunicaciones</h3>
      {showForm ? (
        <ComunicacionesModal
          modalData={selectedComunicacion}
          onCancel={handleCancel}
        />
      ) : (
        <>
          <Grid container spacing={2} className='comunicaciones-cards'>
            {paginatedComunicaciones.map((comunicacion) => (
              <Grid item xs={12} sm={6} key={comunicacion.id}>
                <Card onClick={() => handleCardClick(comunicacion)} sx={{ cursor: 'pointer'}} className='comunicaciones-cards-element'>
                  <CardContent>
                    <Typography variant="h6">{comunicacion.titulo}</Typography>
                    <Typography 
                      variant="body2" 
                      dangerouslySetInnerHTML={{ 
                        __html: comunicacion.descripcion.length > 500 
                          ? comunicacion.descripcion.substring(0, 500) + "..." 
                          : comunicacion.descripcion 
                      }} 
                    />
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          <div className="d-flex justify-content-between align-items-center mt-5">
            <div className="pagination">
              <Button
                variant="contained"
                color="primary"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                style={{ marginRight: '10px' }}
              >
                Anterior
              </Button>
              <span>Página {currentPage} de {totalPages}</span>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                style={{ marginLeft: '10px' }}
              >
                Siguiente
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Comunicaciones;
