import React from 'react';
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
} from '@mui/material';

const RecuperarContraseña = ({ email, setEmail, handleSubmit, loading }) => {
  return (
    <Container maxWidth="sm">
      <Grid container justifyContent="center" sx={{ marginTop: '5rem' }}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
                <img src="logo.png" alt="Logo" style={{ maxWidth: '100%', height: 'auto' }} />
                <Typography variant="h5" sx={{ marginTop: 2, textAlign: 'center' }}>
                  Recuperar Contraseña
                </Typography>
                <Typography variant="body2" sx={{ textAlign: 'center', marginBottom: '1rem' }}>
                  Por favor, ingresa tu dirección de correo electrónico asociada a tu cuenta. Te enviaremos un enlace para restablecer tu contraseña.
                </Typography>
              </div>
              <form onSubmit={handleSubmit}>
                <TextField
                  label="Correo Electrónico"
                  type="email"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  InputProps={{
                    style: { color: 'black' }
                  }}
                  InputLabelProps={{
                    style: { color: 'black' }
                  }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={loading}
                  sx={{ marginTop: '1rem' }}
                >
                  {loading ? 'Enviando...' : 'Enviar Enlace'}
                </Button>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default RecuperarContraseña;