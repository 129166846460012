import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_BASE_URL } from './apiConstants';
import swal from 'sweetalert2';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Alert,
  CircularProgress,
  Box
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

const Login = ({ username, password, loading, error, setUsername, setPassword, handleSubmit }) => {
  const [showDiv, setshowDiv] = useState(false);
  const [email, setEmail] = useState('');
  const handleForgotPassword = () => {
    setshowDiv(true)
  };

  const envioForgotPassword = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/users/request-password-recovery`, { userEmail: email });
      
      const { id, userPasswordRecoveryTokenExpirationDatetime, userPasswordRecoveryToken } = response.data;
  
      // Mostrar confirmación con swal
      swal.fire(
        'Correo enviado',
        `Se ha enviado un enlace de recuperación a tu correo. Tienes hasta ${new Date(userPasswordRecoveryTokenExpirationDatetime).toLocaleString()} para restablecer tu contraseña.`,
        'success'
      );

      // Opcional: Si quisieras mostrar el token (ejemplo) o alguna acción con los datos
      console.log("Token de recuperación:", userPasswordRecoveryToken);
  
    } catch (error) {
      console.error("Error en el envío de correo:", error);
  
      swal.fire(
        'Error',
        'Hubo un problema al eliminar el registro.',
        'error'
      );
    }
  }
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container maxWidth="sm">
      {!showDiv ? (
        <Grid container justifyContent="center" sx={{ marginTop: '5rem' }}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
                <img src="logo.png" alt="Logo" style={{ maxWidth: '100%', height: 'auto' }} />
                <Typography variant="h5" sx={{ marginTop: 2 }}>
                  ¡Te damos la bienvenida nuevamente!
                </Typography>
                <Typography variant="body2">
                  Ingresa tus datos para acceder a tu cuenta
                </Typography>
              </div>
              <form onSubmit={handleSubmit}>
                <TextField
                  label="Nombre de Usuario"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  InputProps={{
                    style: { color: 'black' }
                  }}
                  InputLabelProps={{
                    style: { color: 'black' }
                  }}
                />
                <TextField
                  label="Contraseña"
                  type={showPassword ? 'text' : 'password'} // Alterna entre 'text' y 'password'
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    style: { color: 'black' }
                  }}
                  InputLabelProps={{
                    style: { color: 'black' }
                  }}
                />
                <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ marginTop: '1rem' }}>
                  <Button variant="text" onClick={handleForgotPassword}>
                    ¿Olvidaste tu contraseña?
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    startIcon={loading && <CircularProgress size={20} />}
                  >
                    {loading ? 'Cargando...' : 'Iniciar Sesión'}
                  </Button>
                </Box>
                {error && <Alert severity="error" sx={{ marginTop: '1rem' }}>{error}</Alert>}
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      ) : (
      <Grid container justifyContent="center" sx={{ marginTop: '5rem' }}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <div style={{ textAlign: 'left', marginBottom: '2rem' }}>
                  <Button 
                    variant="text" 
                    onClick={() => setshowDiv(false)} 
                    startIcon={<ArrowBackIcon />} 
                    sx={{ marginBottom: '1rem' }}
                  >
                    Volver
                  </Button>
                </div>
                <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
                  <img src="logo.png" alt="Logo" style={{ maxWidth: '100%', height: 'auto' }} />
                  <Typography variant="h5" sx={{ marginTop: 2, textAlign: 'center' }}>
                    Recuperar Contraseña
                  </Typography>
                  <Typography variant="body2" sx={{ textAlign: 'center', marginBottom: '1rem' }}>
                    Por favor, ingresa tu dirección de correo electrónico asociada a tu cuenta. Te enviaremos un enlace para restablecer tu contraseña.
                  </Typography>
                </div>
                <TextField
                    label="Correo Electrónico"
                    type="email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    InputProps={{
                      style: { color: 'black' }
                    }}
                    InputLabelProps={{
                      style: { color: 'black' }
                    }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={loading}
                    sx={{ marginTop: '1rem' }}
                    onClick={envioForgotPassword}
                  >
                    {loading ? 'Enviando...' : 'Enviar Enlace'}
                  </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        
      )}
      
    </Container>
  );
};

export default Login;
