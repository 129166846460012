import React from 'react';
import { Grid, Typography, List, ListItem, ListItemIcon, ListItemText, Box } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import ReceiptIcon from '@mui/icons-material/Receipt';
import GavelIcon from '@mui/icons-material/Gavel';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import { useNavigate } from 'react-router-dom';

const DocumentosCard = ({ usuario }) => {
    const navigate = useNavigate();

    const handleContratos = () => {
        navigate('/ContratosUser');
    };

    const handleLiquidaciones = () => {
        navigate('/LiquidacionesUser');
    };

    const handleReglamentos = () => {
        navigate('/ReglamentosUser');
    };

    const handleOtros = () => {
        navigate('/OtrosUser');
    };

      
  return (
    <List>
      <Grid container spacing={2}>
        
        {/* Liquidaciones */}
        <Grid item xs={6} sm={6} md={3} sx={{height: '84px', width:'192px'}}>
            <ListItem
                button
                sx={{
                  height: '84px', 
                  border: '1px solid rgba(63, 81, 181, 0.3)', // Borde azul con 50% de transparencia
                  borderRadius: '4px',          // Añade esquinas redondeadas
                  padding: '10px 15px',         // Agrega padding para darle apariencia de botón
                  marginBottom: '10px',         // Agrega margen inferior para separación
                  '&:hover': {
                    backgroundColor: '#fafafa', // Cambia el color de fondo al pasar el mouse
                  },
                }}
                onClick={handleLiquidaciones}

            >   
           <ListItemIcon>
                <Box
                  sx={{
                    borderRadius: '50%',            // Hacer el contenedor circular
                    border: '2px solid #E0F2F1',    // Borde de color #E0F2F1
                    backgroundColor: '#E0F2F1',     // Fondo del color #E0F2F1
                    padding: '8px',                 // Espaciado dentro del círculo
                    display: 'flex',                // Centrar el ícono
                    justifyContent: 'center',       // Centrar horizontalmente
                    alignItems: 'center',           // Centrar verticalmente
                  }}
                >
                <img 
                  src="/cards/001.png"    // Ruta del archivo PNG
                  alt="Liquidaciones" 
                  style={{ width: '24px', height: '24px' }}  // Tamaño del ícono
                />              
              </Box>
            </ListItemIcon>
            <ListItemText primary="Liquidaciones" />
          </ListItem>
        </Grid>

        {/* Contratos y Anexos */}
        <Grid item xs={6} sm={6} md={3}>
            <ListItem
                button
                sx={{
                  height: '84px', 
                  border: '1px solid rgba(63, 81, 181, 0.3)', // Borde azul con 50% de transparencia
                  borderRadius: '4px',          // Añade esquinas redondeadas
                  padding: '10px 15px',         // Agrega padding para darle apariencia de botón
                  marginBottom: '10px',         // Agrega margen inferior para separación
                  '&:hover': {
                    backgroundColor: '#fafafa', // Cambia el color de fondo al pasar el mouse
                  },
                }}
                onClick={handleContratos}

            >   
           <ListItemIcon>
                <Box
                  sx={{
                    borderRadius: '50%',            // Hacer el contenedor circular
                    border: '2px solid #E0F2F1',    // Borde de color #E0F2F1
                    backgroundColor: '#E0F2F1',     // Fondo del color #E0F2F1
                    padding: '8px',                 // Espaciado dentro del círculo
                    display: 'flex',                // Centrar el ícono
                    justifyContent: 'center',       // Centrar horizontalmente
                    alignItems: 'center',           // Centrar verticalmente
                  }}
                >
                <img 
                  src="/cards/002.png"    // Ruta del archivo PNG
                  alt="Contratos y Anexos" 
                  style={{ width: '24px', height: '24px' }}  // Tamaño del ícono
                />    
              </Box>
            </ListItemIcon>
            <ListItemText primary="Contratos y Anexos" />
          </ListItem>
        </Grid>

        {/* Reglamentos */}
        <Grid item xs={6} sm={6} md={3}>
            <ListItem
                button
                sx={{
                  height: '84px', 
                  border: '1px solid rgba(63, 81, 181, 0.3)', // Borde azul con 50% de transparencia
                  borderRadius: '4px',          // Añade esquinas redondeadas
                  padding: '10px 15px',         // Agrega padding para darle apariencia de botón
                  marginBottom: '10px',         // Agrega margen inferior para separación
                  '&:hover': {
                    backgroundColor: '#fafafa', // Cambia el color de fondo al pasar el mouse
                  },
                }}
                onClick={handleReglamentos}
            >   
           <ListItemIcon>
                <Box
                  sx={{
                    borderRadius: '50%',            // Hacer el contenedor circular
                    border: '2px solid #E0F2F1',    // Borde de color #E0F2F1
                    backgroundColor: '#E0F2F1',     // Fondo del color #E0F2F1
                    padding: '8px',                 // Espaciado dentro del círculo
                    display: 'flex',                // Centrar el ícono
                    justifyContent: 'center',       // Centrar horizontalmente
                    alignItems: 'center',           // Centrar verticalmente
                  }}
                >
                <img 
                  src="/cards/003.png"    // Ruta del archivo PNG
                  alt="Reglamentos" 
                  style={{ width: '24px', height: '24px' }}  // Tamaño del ícono
                />    
              </Box>
            </ListItemIcon>
            <ListItemText primary="Reglamentos" />
          </ListItem>
        </Grid>

        {/* Otros */}
        <Grid item xs={6} sm={6} md={3}>
            <ListItem
                button
                sx={{
                  height: '84px', 
                  border: '1px solid rgba(63, 81, 181, 0.3)', // Borde azul con 50% de transparencia
                  borderRadius: '4px',          // Añade esquinas redondeadas
                  padding: '10px 15px',         // Agrega padding para darle apariencia de botón
                  marginBottom: '10px',         // Agrega margen inferior para separación
                  '&:hover': {
                    backgroundColor: '#fafafa', // Cambia el color de fondo al pasar el mouse
                  },
                }}
                onClick={handleOtros}
            >   
           <ListItemIcon>
                <Box
                  sx={{
                    borderRadius: '50%',            // Hacer el contenedor circular
                    border: '2px solid #E0F2F1',    // Borde de color #E0F2F1
                    backgroundColor: '#E0F2F1',     // Fondo del color #E0F2F1
                    padding: '8px',                 // Espaciado dentro del círculo
                    display: 'flex',                // Centrar el ícono
                    justifyContent: 'center',       // Centrar horizontalmente
                    alignItems: 'center',           // Centrar verticalmente
                  }}
                >
                <img 
                  src="/cards/004.png"    // Ruta del archivo PNG
                  alt="Otros" 
                  style={{ width: '24px', height: '24px' }}  // Tamaño del ícono
                />    
              </Box>
            </ListItemIcon>
            <ListItemText primary="Otros" />
          </ListItem>
        </Grid>
      </Grid>
    </List>
  );
};

export default DocumentosCard;
