import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
import Header from './components/config/Header';
import Sidebar from './components/config/Sidebar';
import Panel from './components/config/Panel';
import EmpresaList from './components/config/EmpresaList';
import Login from './components/config/Login';
import Breadcrumbs from './components/config/Breadcrumbs';
import { API_BASE_URL } from './components/config/apiConstants';
import {
  Box, Container, CssBaseline, IconButton, Drawer, useMediaQuery, Button, Grid
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { green } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './App.css';
import swal from 'sweetalert2';

import {
  setLoggedIn,
  setUserDNI,
  setUserID,
  setEmpresaId,
  setEmpresas,
  setCurrentOption,
  setRoleSession,
  setShowDashTrab,
  setUsername,
  setPassword,
  setUserSession,
  setPhotoWorker,
  setCargo,
  setNombre,
  setToken,
} from './actions';
import { clearData } from "./actions";

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    loggedIn,
    userDNI,
    UserID,
    empresaId,
    empresas,
    currentOption,
    roleSession,
    username,
    password,
    loading,
    error,
    token,
  } = useSelector((state) => state);
  const { userSession, photoWorker, cargo, nombre } = useSelector((state) => state);

  const [sidebarVisible, setSidebarVisible] = useState(false);
  const isMobileView = useMediaQuery('(max-width:768px)');

  const theme = createTheme({
    palette: {
      primary: {
        main: green[800], // Green tones for the primary color
      },
      text: {
        primary: '#FFFFFF', // White text for primary text
      },
    },
  });

  const checkTokenValidity = async () => {
    try {
      await axios.post(`${API_BASE_URL}/token/check-token`, { token, userDNI, roleSession });
    } catch (error) {
      console.error('Token invalid or check-token request failed:', error);
      handleLogout(); // Logout user if token is invalid
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (loggedIn) {
        checkTokenValidity();
      }
    }, 10 * 1000);

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [loggedIn]);


  useEffect(() => {
    return () => {
      // Limpia el estado de Redux al desmontar
      dispatch(clearData());
    };
  }, [dispatch]);
  
  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem('isReload', 'true');
    };
  
    window.addEventListener('beforeunload', handleBeforeUnload);
  
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const handleKeydown = (event) => {
      if (event.key === "F5") {
        event.preventDefault(); // Previene la recarga predeterminada
        if (roleSession === "3" && !window.location.pathname.startsWith('/UserDashboard')) {
          navigate('/UserDashboard', { replace: true });
        } else if (roleSession === "2" && empresaId && !window.location.pathname.startsWith(`/Empresas/${empresaId}`)) {
          navigate(`/Empresas/${empresaId}`, { replace: true });
          console.log("111")

        } else if (roleSession === "1" && !window.location.pathname.startsWith('/Empresas')) {
          navigate('/Empresas', { replace: true });
        }
      }
    };

    window.addEventListener("keydown", handleKeydown);

    return () => {
      window.removeEventListener("keydown", handleKeydown);
    };
  }, []);

  useEffect(() => {
    // Load data from localStorage
    const savedUserDNI = localStorage.getItem('userDNI');
    const savedUserID = localStorage.getItem('userID');
    const savedEmpresaId = localStorage.getItem('empresaId');
    const savedRoleSession = localStorage.getItem('roleSession');
    const savedToken = localStorage.getItem('token');

    if (savedUserDNI && savedUserID && savedRoleSession && savedToken) {
      dispatch(setLoggedIn(true));
      dispatch(setUserDNI(savedUserDNI));
      dispatch(setUserID(savedUserID));
      dispatch(setEmpresaId(savedEmpresaId));
      dispatch(setRoleSession(savedRoleSession));
      dispatch(setToken(savedToken));
      const isReload = localStorage.getItem('isReload') === 'true';
      if (isReload) {
        // Si es una recarga, verifica si está en una ruta válida
        //// Limpia el flag
        if (roleSession === "3" && !window.location.pathname.startsWith('/UserDashboard')) {
          navigate('/UserDashboard', { replace: true });
          localStorage.removeItem('isReload'); 
        } else if (roleSession === "2" && savedEmpresaId && !window.location.pathname.startsWith(`/Empresas/${savedEmpresaId}`)) {
          navigate(`/Empresas/${savedEmpresaId}`, { replace: true });
          localStorage.removeItem('isReload'); 
          console.log("222")
        } else if (roleSession === "1" && !window.location.pathname.startsWith('/Empresas')) {
          navigate('/Empresas', { replace: true });
          localStorage.removeItem('isReload'); 
        }
      }

    } else {
      navigate('/login');
    }
  }, [dispatch, navigate]);


  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
      alert("Evento beforeunload activado. La página intentará recargarse o cerrarse.");
    };

    const handleKeyDown = (event) => {
      if (event.key === "F5" || (event.ctrlKey && event.key === "r")) {
        event.preventDefault();
        alert(`Tecla presionada: ${event.key === "F5" ? "F5" : "Ctrl+R"}`);
        swal.fire("Error", "Refrescar está deshabilitado", "error");
      }
    };

    // Agregar eventos
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      // Limpiar eventos al desmontar el componente
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (loggedIn && userDNI && !empresaId && roleSession !== "1") {
      // Evita redireccionar automáticamente al superadmin
      axios
        .get(`${API_BASE_URL}/empresas/trabajadores/${userDNI}`)
        .then((response) => {
          dispatch(setEmpresas(response.data));
          if (response.data.length === 1) {
            const empresa = response.data[0];
            dispatch(setEmpresaId(empresa.id));
            localStorage.setItem('empresaId', empresa.id);
            if (roleSession === "3") {
              navigate('/UserDashboard', { replace: true });
            } else if (roleSession === "2") {
              navigate(`/Empresas/${empresa.id}`, { replace: true });
              console.log("333")

            }
          } else if (roleSession === "1") {
            navigate('/Empresas', { replace: true }); // Navega a la lista de empresas para el superadmin
          }
        })
        .catch((error) => console.error('Error al obtener la lista de empresas:', error));
    }
  }, [loggedIn, userDNI, dispatch, roleSession, navigate, empresaId]);
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_BASE_URL}/users/sign-in`, {
        userEmail: username,
        userPassword: password,
      });
  
      const { userDNI, user_id, role_id, foto, cargo, nombre, token } = response.data;
  
      // Guardar en Redux
      dispatch(setUserSession(userDNI));
      dispatch(setRoleSession(role_id));
      dispatch(setUserDNI(userDNI));
      dispatch(setUserID(user_id));
      dispatch(setPhotoWorker(foto));
      dispatch(setCargo(cargo));
      dispatch(setNombre(nombre));
      dispatch(setToken(token));
      dispatch(setLoggedIn(true));
  
      // Guardar en localStorage
      localStorage.setItem('userDNI', userDNI);
      localStorage.setItem('userID', user_id);
      localStorage.setItem('roleSession', role_id);
      localStorage.setItem('token', token);
  
      // Redireccionar según el rol
      if (role_id === "3") {
        navigate('/UserDashboard', { replace: true }); // Usuarios normales
      } else if (role_id === "2") {
        if (empresaId) {
          localStorage.setItem('empresaId', empresaId);
          navigate(`/Empresas/${empresaId}`, { replace: true });
          console.log("444")

        } else {
          navigate('/UserDashboard', { replace: true }); // Usuarios normales
        }
      } else if (role_id === "1") {
        navigate('/Empresas', { replace: true }); // Superadmin
      }
    } catch (error) {
      swal.fire('Error', 'Usuario o contraseña incorrectos', 'error');
      console.error('Usuario o contraseña incorrectos:', error);
    }
  };

  const handleSelectChange = (id) => {
    if (id){
      dispatch(setEmpresaId(id));
      localStorage.setItem('empresaId', id);
    
      // Redirige solo si el rol requiere estar dentro de una empresa específica
      if (roleSession === "2") {
        navigate(`/Empresas/${id}`, { replace: true });
        console.log("555")
  
      } else if (roleSession === "1") {
        // Para el superadmin, navega si es necesario
        navigate(`/Empresas`, { replace: true });
      }
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    dispatch(clearData());
    dispatch(setLoggedIn(false));
    dispatch(setUserDNI(''));
    dispatch(setEmpresaId(''));
    dispatch(setToken(''));
    navigate('/login');
  };

  if (!loggedIn) {
    return (
      <ThemeProvider theme={theme}>
        <Login
          username={username}
          password={password}
          loading={loading}
          error={error}
          setUsername={(value) => dispatch(setUsername(value))}
          setPassword={(value) => dispatch(setPassword(value))}
          handleSubmit={handleSubmit}
        />
      </ThemeProvider>
    );
  }

  if (roleSession === "1" || empresaId) {
    return (
      <ThemeProvider theme={theme}>
        <Container disableGutters maxWidth={false} className="container-root">
          <Header onLogout={handleLogout} className="header-root" />
          {isMobileView ? (
            <>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => setSidebarVisible(true)}
                className="icon-button"
              >
                <MenuIcon />
              </IconButton>
              <Drawer anchor="left" open={sidebarVisible} onClose={() => setSidebarVisible(false)}>
                <Sidebar handleLogout={handleLogout} selectedEmpresa={empresaId} />
              </Drawer>
              <Grid item xs={12}>
                <Breadcrumbs currentOption={currentOption} selectedEmpresa={empresaId} />
                <Panel
                  currentOption={currentOption}
                  userDNI={userDNI}
                  empresaId={empresaId}
                  setCurrentOption={(option) => dispatch(setCurrentOption(option))}
                />
              </Grid>
            </>
          ) : (
            <Box display="flex">
              <Sidebar handleLogout={handleLogout} selectedEmpresa={empresaId} />
              <Box flex={1}>
                <Breadcrumbs currentOption={currentOption} selectedEmpresa={empresaId} />
                <Panel
                  currentOption={currentOption}
                  userDNI={userDNI}
                  empresaId={empresaId}
                  setCurrentOption={(option) => dispatch(setCurrentOption(option))}
                />
              </Box>
            </Box>
          )}
        </Container>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <EmpresaList
        empresas={empresas}
        empresaId={empresaId}
        handleSelectChange={handleSelectChange}
      />
    </ThemeProvider>
  );
};

export default App;
